/**
* Variable file 
* place global variables here:
* 
*/


$font-stack: system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Ubuntu,Cantarell,Oxygen,Roboto,Helvetica,Arial,sans-serif;
$head-stack: $font-stack;

$black:#444;
$white:#fff;

$bgcolor:#f8f8f8;
$font-color:#46484a;

$blue: rgb(61, 145, 184);
$green: rgb(80, 142, 47);
$gold: rgb(195, 163, 75);
$grey: #e4ecf6;

$primaryColor:#e3c28f;
$secondaryColor:#739b75;
$tertiaryColor:#46484a;

$gap: 15px;
$size-container: 1100px;
$size-read-width: 750px;