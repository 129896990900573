body.single #main{
    margin-top: 0;
}

.page-header{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background: $blue;
    min-height: 200px;
    text-align: center;

    h1{
        font-size: 2rem;
        color: $white;
    }
}

.page-cover{
    min-height: 65vh;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
}

.post-container{
    margin: -30vh auto 0 auto;
    background: $white;
    padding: $gap;
    max-width: $size-container;
    width: 90%;

    @media only screen and (min-width: 600px){
        padding: $gap * 3;
    }

    .post-meta{
        font-size: 1.1rem;
        margin-bottom: $gap;
        padding-left: 0;
    }
}

