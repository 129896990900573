/**
* General CSS file 
* Styling for all general classes:
* 
*/

html, body{
	margin:0;
	padding:0;
	width:100%;
    height:100%;
    overflow-x: hidden;
}

body{
	background:#fff;
	font-size:16px;
	color:$font-color;
	font-family:$font-stack;
}

/**
 * firefox hack for flexbox
 */
*{
	min-height:0;
	min-width:0;
}

.container{
    max-width: $size-container;
    margin: 0 auto;
}