.comments{
    max-width: 600px;
    width: 90%;
    margin: 0 auto;   

    .comment{
        padding: 20px;
        margin: -1px -20px;
        border-bottom: 1px solid #e4ecf6;

       
        img{
            max-width: 100%;
        }

        .comment-header{
            position: relative;
            background: transparent;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
            height: auto;

            a{
                text-decoration: none;
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
            }
        }

        .PostMention{
            background: #e4ecf6;
            color: #667c99;
            border-radius: 4px;
            padding: 2px 5px;
            border: 0 !important;
            font-weight: bold;
            text-decoration: none;
        }

        .Post-body{
            p{
                line-height: 145%;
                font-size: 15px;
            }
        }

        time{
            color: #667c99;
            font-size: 13px;
            margin-left: 5px;
        }

        .username{
            color: #111;
            font-weight: bold;
            font-size: 14px;
        }

        .avatar{
            width: 64px;
            height: 64px;
            border-radius: 32px;
        }

        

        @media only screen and (min-width:440px){
            padding: 20px 20px 20px 105px;
            .comment-header{
                justify-content: flex-start;
            }

            .avatar{
                left: -85px;
                position: absolute;
                width: 64px;
                height: 64px;
                border-radius: 64px;
                font-size: 32px;
                line-height: 64px;
                background: rgb(229, 160, 180);
            }
        }

    }

    .loader{
        width: 150px;
        margin: 20px auto;
        text-align: center;
        p{
            padding-bottom: 5px;
            margin: 0 0 -10px 0;
        }
    }

    .empty-state{
        text-align: center;
        display: block;
    }

    .discussion-button{
        background: $blue;
        border-radius: 4px;
        color: white;
        font-weight: bold;
        font-size: 15px;
        padding: 10px 20px;
        margin: 20px 0 0 0;
        float: right; 
        i{
            margin-left: 15px;
            color: white;
        }
    }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #111;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}