/**
* Main CSS file 
* this is where all your imports go.
* 
*/
/**
* normalize.css v3.0.1
* git.io/normalize
* 
*/
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */ }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11 and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background: transparent; }

/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Address styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * Remove border when inside `a` element in IE 8/9/10.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */ }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * It's recommended that you don't attempt to style these elements.
 * Firefox's implementation doesn't respect box-sizing, padding, or width.
 *
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */
input[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/**
* Variable file 
* place global variables here:
* 
*/
/**
* Mixin file 
* place global mixins here:
* 
*/
/* Boxsizing shortcut: */
/* Retina images: */
/* "Hard" gradients: */
/* REM font sizes: */
/* Increase frame-rate when scrolling by not registering pointer-events:*/
.disable-hover, .disable-hover * {
  pointer-events: none !important; }

/* Screenreader text support: */
.visuallyhidden {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden; }
  .visuallyhidden:focus {
    background-color: #fff;
    border-radius: 3px;
    clip: auto !important;
    color: #e3c28f;
    display: block;
    font-size: rem(14px);
    height: auto;
    left: 5px;
    padding: 15px 23px 14px;
    top: 5px;
    width: auto;
    z-index: 100000;
    /* Above WP toolbar. */ }

/**
* All base typography 
* 
*/
h1, h2, h3, h4, h5, h6 {
  color: #46484a;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, Cantarell, Oxygen, Roboto, Helvetica, Arial, sans-serif; }

p, a, span, ol, ul, li, strong, em, i, b, big, small, tt, abbr, acronym,
cite, code, dfn, em, kbd, samp, var, br, sub, sup, button, input, label,
select, textarea {
  color: #46484a;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, Cantarell, Oxygen, Roboto, Helvetica, Arial, sans-serif; }

h1 {
  font-size: 3rem; }

h2 {
  font-size: 2rem; }

h3 {
  font-size: 1.7rem; }

h4 {
  font-size: 1.5rem; }

h5 {
  font-size: 1.2rem; }

h6 {
  font-size: 1rem; }

/**
* UI CSS file 
* Everything that enables user-interaction gets bootstrapped here
* 
*/
.button, .btn, a[class^="btn--"] {
  padding: 10px 11px;
  background: #000;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  line-height: 100%; }
  .button:hover, .btn:hover, a[class^="btn--"]:hover {
    background: #444; }

/**
* Footer CSS file 
* Styling for the footer:
* 
*/
.wrapper {
  min-height: 100%;
  margin-bottom: -50px; }

footer {
  height: 50px;
  background: #444;
  width: 100%; }

/**
* General CSS file 
* Styling for all general classes:
* 
*/
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden; }

body {
  background: #fff;
  font-size: 16px;
  color: #46484a;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, Cantarell, Oxygen, Roboto, Helvetica, Arial, sans-serif; }

/**
 * firefox hack for flexbox
 */
* {
  min-height: 0;
  min-width: 0; }

.container {
  max-width: 1100px;
  margin: 0 auto; }

/**
* Header CSS file 
* Styling for the header:
* 
*/
header {
  width: 100%;
  padding: 7px 0;
  background: #3d91b8;
  /**
	 * Mobile:
	 */ }
  header .container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    height: 100%; }
  header .logo {
    display: block;
    padding: 0;
    text-decoration: none;
    min-height: 30px;
    height: 30px;
    text-align: center; }
    header .logo img {
      max-height: 100%;
      max-width: initial;
      width: auto; }
    header .logo h1 {
      font-size: 32px; }
  header ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    header ul li {
      display: inline-block; }
    header ul a {
      display: block;
      padding: 12px 20px;
      color: white; }
    header ul a:hover {
      background: rgba(0, 0, 0, 0.2); }
  @media only screen and (min-width: 460px) {
    header {
      height: 40px; }
      header .container {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        height: 100%; }
      header .logo {
        min-height: initial;
        height: 100%; } }
  header .mobile-nav {
    position: absolute;
    right: 15px;
    display: block;
    width: 290px; }
    header .mobile-nav ul {
      width: 100%; }
      header .mobile-nav ul li {
        display: block; }
  header .menu-switch {
    width: 44px;
    height: 44px;
    padding-top: 8px;
    text-align: center;
    background: #444;
    border-radius: 4px;
    cursor: pointer;
    float: right; }
    header .menu-switch i {
      font-size: rem(24px);
      color: #fff; }
    header .menu-switch:hover {
      background: rgba(0, 0, 0, 0.25); }

#main {
  margin-top: 108px;
  padding-bottom: 100px; }

/**
* nav CSS file 
* Styling for the header:
* 
*/
.overview .post h2 {
  padding: 0 15px;
  margin: 0 0 10px 0; }

.overview .post .post-meta {
  margin-bottom: 30px; }

.overview .post .post-content {
  padding: 15px;
  display: flex;
  flex-flow: row wrap; }
  .overview .post .post-content p, .overview .post .post-content a {
    flex: 1 0 100%; }
  .overview .post .post-content .more-link {
    padding-right: 30px;
    background: url("../images/arrow-icon.svg") no-repeat;
    background-position: 100% center;
    background-size: auto 80%;
    flex: 0 1 auto;
    display: inline-block;
    font-weight: bold; }
  .overview .post .post-content .wp-block-image {
    flex: 1 0 30%; }
  .overview .post .post-content .wp-block-image + p {
    flex: 1 0 60%;
    margin-left: 15px; }
  .overview .post .post-content .wp-block-image figure {
    float: none;
    margin: 0;
    padding: 0; }
    .overview .post .post-content .wp-block-image figure img {
      width: 100%;
      height: auto; }

.pagination {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center; }

.pagination a, .pagination .page-numbers {
  margin-left: 10px;
  display: block;
  font-weight: bold;
  background: #eee;
  padding: 5px 10px;
  text-align: center; }

.pagination a:hover {
  background: #3d91b8;
  color: white; }

.pagination .current {
  font-weight: normal;
  background: #444;
  color: white; }

.post {
  clear: both;
  margin: 45px auto;
  padding-bottom: 45px;
  border-bottom: 1px solid #e4ecf6;
  width: 90%;
  max-width: 750px;
  line-height: 150%;
  display: flex;
  flex-flow: column nowrap; }
  .post a {
    color: #3d91b8;
    text-decoration: underline; }
    .post a:hover {
      color: #444; }

.post-meta {
  color: #777777;
  font-size: .9rem;
  padding: 0 15px;
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 10px; }
  .post-meta > * {
    margin-right: 15px; }
  @media only screen and (min-width: 480px) {
    .post-meta {
      flex-flow: row nowrap;
      padding-bottom: 0; } }

.post-content {
  padding: 15px; }
  .post-content p {
    margin: 0 0 30px 0; }

.post.single .post-content {
  padding: 0; }

.post-thumb {
  margin: 0 -30px;
  z-index: -1; }
  .post-thumb img {
    width: 100%;
    height: auto; }

.post-thumb + .post-content {
  margin-top: -20px;
  background: white; }

.comments {
  max-width: 600px;
  width: 90%;
  margin: 0 auto; }
  .comments .comment {
    padding: 20px;
    margin: -1px -20px;
    border-bottom: 1px solid #e4ecf6; }
    .comments .comment img {
      max-width: 100%; }
    .comments .comment .comment-header {
      position: relative;
      background: transparent;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      height: auto; }
      .comments .comment .comment-header a {
        text-decoration: none;
        display: flex;
        flex-flow: column nowrap;
        align-items: center; }
    .comments .comment .PostMention {
      background: #e4ecf6;
      color: #667c99;
      border-radius: 4px;
      padding: 2px 5px;
      border: 0 !important;
      font-weight: bold;
      text-decoration: none; }
    .comments .comment .Post-body p {
      line-height: 145%;
      font-size: 15px; }
    .comments .comment time {
      color: #667c99;
      font-size: 13px;
      margin-left: 5px; }
    .comments .comment .username {
      color: #111;
      font-weight: bold;
      font-size: 14px; }
    .comments .comment .avatar {
      width: 64px;
      height: 64px;
      border-radius: 32px; }
    @media only screen and (min-width: 440px) {
      .comments .comment {
        padding: 20px 20px 20px 105px; }
        .comments .comment .comment-header {
          justify-content: flex-start; }
        .comments .comment .avatar {
          left: -85px;
          position: absolute;
          width: 64px;
          height: 64px;
          border-radius: 64px;
          font-size: 32px;
          line-height: 64px;
          background: #e5a0b4; } }
  .comments .loader {
    width: 150px;
    margin: 20px auto;
    text-align: center; }
    .comments .loader p {
      padding-bottom: 5px;
      margin: 0 0 -10px 0; }
  .comments .empty-state {
    text-align: center;
    display: block; }
  .comments .discussion-button {
    background: #3d91b8;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    padding: 10px 20px;
    margin: 20px 0 0 0;
    float: right; }
    .comments .discussion-button i {
      margin-left: 15px;
      color: white; }

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #111;
  animation-timing-function: cubic-bezier(0, 1, 1, 0); }

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite; }

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite; }

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite; }

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1); } }

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(0); } }

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0); }
  100% {
    transform: translate(24px, 0); } }

/**
* Home CSS file 
* Styling for home:
* 
*/
body.single #main {
  margin-top: 0; }

.page-header {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background: #3d91b8;
  min-height: 200px;
  text-align: center; }
  .page-header h1 {
    font-size: 2rem;
    color: #fff; }

.page-cover {
  min-height: 65vh;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover; }

.post-container {
  margin: -30vh auto 0 auto;
  background: #fff;
  padding: 15px;
  max-width: 1100px;
  width: 90%; }
  @media only screen and (min-width: 600px) {
    .post-container {
      padding: 45px; } }
  .post-container .post-meta {
    font-size: 1.1rem;
    margin-bottom: 15px;
    padding-left: 0; }
