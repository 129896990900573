/**
* Main CSS file 
* this is where all your imports go.
* 
*/

//normalize
@import 'vendors/normalize';

//Custom variables:
@import 'variables';

//Core styling:
@import 'base/mixins';
@import 'base/a11y';
@import 'base/typography';
@import 'base/ui';

// Components:
@import 'components/footer';
@import 'components/general';
@import 'components/header';
@import 'components/nav';
@import 'components/overview';
@import 'components/post';
@import 'components/comment';


//Pages:
@import 'pages/home';
@import 'pages/single';