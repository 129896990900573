.post{
    clear: both;
    margin: $gap * 3 auto;
    padding-bottom: $gap * 3;
    border-bottom: 1px solid $grey;
    width: 90%;
    max-width: $size-read-width;
    line-height: 150%;
    display: flex;
    flex-flow: column nowrap;

    a{
        color: $blue;
        text-decoration: underline;
        &:hover{
            color: $black;
        }
    }
}

.post-meta{
    color: lighten( $black, 20% );
    font-size: .9rem;
    padding: 0 $gap;
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 10px;
    > * {
        margin-right: $gap;
    }

    @media only screen and (min-width:480px){
        flex-flow: row nowrap;
        padding-bottom: 0;
    }
}

.post-content{
    padding: $gap;

    p{
        margin: 0 0 $gap * 2 0;
    }
}

.post.single .post-content{
    padding: 0;
}

.post-thumb{
    margin: 0 $gap * -2;
    z-index: -1;
    img{
        width: 100%;
        height: auto;
    }
}

.post-thumb + .post-content{
    margin-top: -20px;
    background: white;
}