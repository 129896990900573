/**
* Mixin file 
* place global mixins here:
* 
*/


/* Boxsizing shortcut: */
@mixin borderbox(){
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

/* Retina images: */
@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}


/* "Hard" gradients: */
@mixin gradient-hard( $start, $end:#fff, $direction:"horizontal" ) {

  @if $direction == "horizontal"{

    @include gradient-hard-horizontal( $start, $end );

  }@else{

    @include gradient-hard-vertical( $start, $end );

  }

}

@mixin gradient-hard-horizontal( $from, $to ) {
  background: $to;
  background:  linear-gradient(to right, $from 0%,$from 50%,$to 50%,$to 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
}

@mixin gradient-hard-vertical( $from, $to ) {
  background: $to;
  background: linear-gradient(to bottom, $from 0%,$from 50%,$to 50%,$to 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$from', endColorstr='$to',GradientType=1 );
}

@mixin break-container(){
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

/* REM font sizes: */
@function calculateRem($size) {
  $remSize: $size / 16px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}