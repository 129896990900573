/**
* Header CSS file 
* Styling for the header:
* 
*/

header{
	width: 100%;
	padding: 7px 0;
    background: $blue;

    
    .container{
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        height: 100%;
    }

	.logo{
        display: block;
		padding:0;
        text-decoration:none;
        min-height: 30px;
        height: 30px;
        text-align: center;
        img{
            max-height: 100%;
            max-width: initial;
            width: auto;
        }

		h1{
			font-size:32px;
		}
	}


	ul{
		margin:0;
		padding:0;
		list-style:none;


		li{ display:inline-block;}

		a{
			display:block;
			padding:12px 20px;
            color: white;
		}

		a:hover{
            background: rgba(0,0,0,.2);
		}
    }
    
    @media only screen and (min-width: 460px ){
        height: 40px;

        .container{
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            height: 100%;
        }

        .logo{
            min-height: initial;
            height: 100%;
        }
    }


	/**
	 * Mobile:
	 */
	.mobile-nav{
		position:absolute;
		right:15px;
		display:block;
		width:290px;
		ul{
			width:100%;
			li{
				display:block;
			}
		}
	}

	.menu-switch{
		width:44px;
		height:44px;
		padding-top:8px;
		text-align:center;
		background:#444;
		border-radius:4px;
		cursor:pointer;
		float:right;

		i{
			font-size:rem( 24px );
			color:#fff;
		}

		&:hover{
			background:rgba( 0,0,0, .25 );
		}
	}
}


#main{
	margin-top:108px; //account for sticky menu
	padding-bottom:100px; //sticky footer
}