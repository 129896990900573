.overview{
    
    .post{

        h2{
            padding: 0 $gap;
            margin: 0 0 10px 0;
        }

        .post-meta{
            margin-bottom: $gap * 2;
        }

        .post-content{
            padding: $gap;
            display: flex;
            flex-flow: row wrap;

            p, a{
                flex: 1 0 100%;
            }

            .more-link{
                padding-right: 30px;
                background: url( '../images/arrow-icon.svg' ) no-repeat;
                background-position: 100% center;
                background-size: auto 80%;
                flex: 0 1 auto;
                display: inline-block;
                font-weight: bold;
            }

            .wp-block-image{
                flex: 1 0 30%;
            }
            
            .wp-block-image + p {
                flex: 1 0 60%;
                margin-left: $gap;
            }

            .wp-block-image figure{
                float: none;
                margin: 0;
                padding: 0;
                img{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
}

.pagination{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

.pagination a, .pagination .page-numbers{
    margin-left: 10px;
    display: block;
    font-weight: bold;
    background: #eee;
    padding: 5px 10px;
    text-align: center;
}

.pagination a:hover{
    background: $blue;
    color: white;
}

.pagination .current{
    font-weight: normal;
    background: #444;
    color: white;
}